.rtl-theme {
  direction: rtl;
  .header-left .nav-menu {
    direction: ltr;
  }
  .header-left {
    right: 0;
    left: auto;
  }
  @media (min-width: 768px) {
    &.main-left {
      padding-left: 0;
      padding-right: 80px;
    }
  }
  .about-info .info-list label {
    padding-right: 0;
    padding-left: 10px;
  }
  .ml-auto,
  .mx-auto {
    margin-left: 0 !important;
    margin-right: auto;
  }
}
